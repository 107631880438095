<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">PortToolio</a>
  </div>
  <div class="versionData" *ngIf="versionData != undefined">
    <div style="width: 100px; float:left;">
      <div *ngIf="versionData.backend != undefined && versionData.backend.date != undefined">Backend:</div>
      <div *ngIf="versionData.dashboard != undefined && versionData.dashboard.date != undefined">Dashboard:</div>
      <div *ngIf="versionData.frontend != undefined && versionData.frontend.date != undefined">Frontend:</div>
    </div>
    <div style="float: left;">
      <div *ngIf="versionData.backend != undefined && versionData.backend.date != undefined">{{versionData.backend.date}}</div>
      <div *ngIf="versionData.dashboard != undefined && versionData.dashboard.date != undefined">{{versionData.dashboard.date}}</div>
      <div *ngIf="versionData.frontend != undefined && versionData.frontend.date != undefined">{{versionData.frontend.date}}</div>
    </div>
  </div>
</div>

<div class="header-container">
</div>
