import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResponse } from '../../models/GenericResponse';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root',
})
export class BasicService {

  constructor(
    public http: HttpClient,
    public dataService: DataService
  ) { }

  public create(route: string, obj: any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${route}`, obj, this.dataService.getHttpOptionsWithToken());
  }

  public update(route: string, obj: any): Observable<GenericResponse<any>> {
    return this.http.put<GenericResponse<any>>(`${route}${obj.id}/`, obj, this.dataService.getHttpOptionsWithToken());
  }

  public delete(route: string, id: string | number): Observable<GenericResponse<undefined>> {
    return this.http.delete<GenericResponse<undefined>>(`${route}${id}/`, this.dataService.getHttpOptionsWithToken());
  }

  public get(route: string, id: string | number): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${route}${id}/`, this.dataService.getHttpOptionsWithToken());
  }

  public getAll(route: string, ): Observable<GenericResponse<any[]>> {
    return this.http.get<GenericResponse<any[]>>(`${route}all/all/`, this.dataService.getHttpOptionsWithToken());
  }
}
