import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResponse } from '../../models/GenericResponse';
import { Setting } from '../../models/Setting';
import { BasicService } from '../basic/basic.service';
import { DataService } from '../data/data.service';
import * as ROUTES from "../ROUTES";

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BasicService {

  constructor(
    public http: HttpClient,
    public dataService: DataService
  ) {
    super(http, dataService);
  }

  public createSetting(setting: Setting): Observable<GenericResponse<Setting>> {
    return this.create(ROUTES.SETTINGS_ROUTE, setting);
  }

  public updateSetting(setting: Setting): Observable<GenericResponse<Setting>> {
    return this.update(ROUTES.SETTINGS_ROUTE, setting);
  }

  public deleteSetting(settingId: string): Observable<GenericResponse<undefined>> {
    return this.delete(ROUTES.SETTINGS_ROUTE, settingId);
  }

  public getSetting(settingId: string): Observable<GenericResponse<Setting>> {
    return this.get(ROUTES.SETTINGS_ROUTE, settingId);
  }

  public getAllSettings(): Observable<GenericResponse<Setting[]>> {
    return this.getAll(ROUTES.SETTINGS_ROUTE);
  }

  public getSettingByKey(key: string): Observable<GenericResponse<Setting>> {
    return this.http.get<GenericResponse<Setting>>(`${ROUTES.SETTINGS_ROUTE}byKey/${key}/`, this.dataService.getHttpOptionsWithToken());
  }

}
