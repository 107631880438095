import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NbButtonModule, NbInputModule, NbLayoutModule, NbThemeModule } from "@nebular/theme";
import { ThemeModule } from "../../@theme/theme.module";
import { LoginComponent } from "./login.component";

@NgModule({
    imports: [
        FormsModule,
        ThemeModule,
        NbThemeModule,
        NbInputModule,
        NbButtonModule,
        NbLayoutModule,
    ],
    declarations: [
        LoginComponent,
    ],
})
export class LoginModule { }
