import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { GenericResponse } from "../models/GenericResponse";
import { AuthService } from "./auth/auth.service";
import { DataService } from "./data/data.service";

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    private session;
    private tokenCheckIntervalInMs = 15000;

    constructor(
        private authService: AuthService,
        private dataService: DataService,
        private router: Router
    ) {}

    public canActivate(): Observable<boolean> {

        const token = this.dataService.getToken();
        if (token === undefined || token === null || token === '') {
            this.router.navigate(['/login']);
            this.stopLoginSession();
            return new Observable<boolean>((observer) => {
                observer.next(false);
                observer.complete();
            });
        }

        return new Observable<boolean>((observer) => {
            this.authService.validateToken(token).subscribe({
                error: (err: HttpErrorResponse) => {
                    // tslint:disable-next-line:no-console
                    console.log(err);
                    this.router.navigate(['/login']);
                    this.stopLoginSession();
                    observer.next(false);
                },
                next: (response: GenericResponse<any>) => {
                    if (response.status === 200) {
                        this.dataService.setToken(response.token);
                        this.authService.loggedInEvent.next();
                        observer.next(true);
                    } else {
                        this.router.navigate(['/login']);
                        this.stopLoginSession();
                        observer.next(false);
                    }
                },
                complete: () => {
                    observer.complete();
                },
            });
        });
    }

    public startLoginSession(): void{
        this.checkToken();
    }

    public stopLoginSession(): void{
        if(this.session != undefined){
            clearInterval(this.session);
        }
    }

    private checkToken(): void{
        this.session = setInterval(async () => {
            this.canActivate().subscribe();
        }, this.tokenCheckIntervalInMs);
    }
}