import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResponse } from '../../models/GenericResponse';
import { User } from '../../models/User';
import { DataService } from '../data/data.service';
import * as ROUTES from "../ROUTES";

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) { }

  public getUserSelf(): Observable<GenericResponse<User>> {
    return this.http.get<GenericResponse<User>>(`${ROUTES.USER_ROUTE}`, this.dataService.getHttpOptionsWithToken());
  }

  public createUser(user: User): Observable<GenericResponse<User>> {
    return this.http.post<GenericResponse<User>>(`${ROUTES.USER_ROUTE}`, user, this.dataService.getHttpOptionsWithToken());
  }

  public updateUser(user: User): Observable<GenericResponse<User>> {
    return this.http.put<GenericResponse<User>>(`${ROUTES.USER_ROUTE}${user.id}/`, user, this.dataService.getHttpOptionsWithToken());
  }

  public deleteUser(userId: number | string): Observable<GenericResponse<undefined>> {
    return this.http.delete<GenericResponse<undefined>>(`${ROUTES.USER_ROUTE}${userId}/`, this.dataService.getHttpOptionsWithToken());
  }

  public getUser(userId: number | string): Observable<GenericResponse<User>> {
    return this.http.get<GenericResponse<User>>(`${ROUTES.USER_ROUTE}${userId}/`, this.dataService.getHttpOptionsWithToken());
  }

  public getAllUsers(): Observable<GenericResponse<User[]>> {
    return this.http.get<GenericResponse<User[]>>(`${ROUTES.USER_ROUTE}all/all/`, this.dataService.getHttpOptionsWithToken());
  }

  public generatePassword(): string {
    let passwordLength = 12;

    let lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    let upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let symbols = ['!', '?', '@'];

    let getRandom = function (array) {
        return array[Math.floor(Math.random() * array.length)];
    };
    let finalCharacters = "";

    finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
    finalCharacters = finalCharacters.concat(getRandom(numbers));
    finalCharacters = finalCharacters.concat(getRandom(symbols));

    for (let i = 1; i < passwordLength - 3; i++) {
      finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
    }

    // shuffle!
    return finalCharacters.split('').sort(function () {
      return 0.5 - Math.random();
    }).join('');
  }

}
