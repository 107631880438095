import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericResponse } from '../../models/GenericResponse';
import { DataService } from '../data/data.service';
import * as ROUTES from "../ROUTES";

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public loggedInEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private dataService: DataService
) { }

  public login(email: string, password: string): Observable<GenericResponse<undefined>> {
    return this.http.post<GenericResponse<undefined>>(`${ROUTES.USER_ROUTE}login/`, {email, password}, this.dataService.getHttpOptionsNoToken());
  }

  public logout(): void {
    this.dataService.clearData();
    this.dataService.setToken(undefined);
    this.router.navigate(['/login']);
  }

  public validateToken(token: string): Observable<any> {
    return this.http.post(`${ROUTES.VALIDATE_TOKEN}`, {}, this.dataService.getHttpOptionsWithToken(token));
  }
}
