import { environment } from "../../environments/environment";


export const BACKEND_URL        =   environment.backendURL;
export const USER_ROUTE         =   `${BACKEND_URL}/user/`;
export const VALIDATE_TOKEN     =   `${USER_ROUTE}token/validate/`;
export const CHILD_ROUTE        =   `${BACKEND_URL}/child/`;
export const DAYCARE_ROUTE      =   `${BACKEND_URL}/daycare/`;
export const PRODUCT_ROUTE      =   `${BACKEND_URL}/product/`;
export const PURCHASE_ROUTE      =   `${BACKEND_URL}/purchase/`;
export const GROUP_ROUTE        =   `${BACKEND_URL}/group/`;
export const INSTITUTION_ROUTE  =   `${BACKEND_URL}/institution/`;
export const TEMPLATE_ROUTE     =   `${BACKEND_URL}/template/`;
export const SETTINGS_ROUTE     =   `${BACKEND_URL}/settings/`;
export const PROJECT_ROUTE     =   `${BACKEND_URL}/project/`;
export const EVENT_ROUTE     =   `${BACKEND_URL}/event/`;
export const EVENT_TYPE_ROUTE     =   `${EVENT_ROUTE}eventTypes/`;
export const SCHEDULING_ROUTE     =   `${BACKEND_URL}/scheduling/trigger/`;
export const CLIP_ART_ROUTE     =   `${BACKEND_URL}/clipart/`;
export const PDF_GENERATION_ROUTE     =   `${BACKEND_URL}/pdf-generation/`;
