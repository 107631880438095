import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  private MESSAGE_PLACEHOLDER = '%str_replace%';

  public LOADING_DATA_FAILED = {
    title: 'Fehler beim Laden der Daten!',
    body: `Beim Laden der Daten ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public LOGIN_FAILED = {
    title: 'Login fehlgeschlagen!',
    body: `Es ist ein Fehler aufgetreten, überprüfen Sie Ihre Eingabe und versuchen Sie es erneut!\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public LOGIN_FAILED_NO_ADMIN = {
    title: 'Login fehlgeschlagen!',
    body: `In diesem Bereich können sich nur Administratoren einloggen!`,
  };
  public LOGIN_MISSING = {
    title: 'Fehlerhafte Eingabe!',
    body: 'Bitte füllen Sie alle Eingabefelder aus und versuchen Sie es erneut!',
  };
  public TOKEN_EXPIRED = {
    title: 'Aus Sicherheitsgründen wurden Sie abgemeldet!',
    body: 'Bitte melden Sie sich erneut an!',
  };
  public MISSING_ANY_MANDATORY_FIELD = {
    title: 'Fehlerhafte Eingabe!',
    body: `Bitte füllen Sie alle Pflichtfelder aus und versuchen Sie es erneut!`,
  };
  public MISSING_ANY_MANDATORY_FIELD_INSTITUTION = {
    title: 'Fehlerhafte Trägerdaten!',
    body: `Bitte füllen Sie alle Pflichtfelder des Trägers aus und versuchen Sie es erneut!`,
  };
  public PASSWORDS_DONT_MATCH = {
    title: 'Die eingegebenen Passwörter stimmen nicht überein!',
    body: 'Bitte überprüfen Sie Ihre Eingabe und versuchen es erneut!',
  };
  public DELETE_USER_SUCCESS = {
    title: 'Nutzer erfolgreich gelöscht!',
    body: `Der Nutzer wurde erfolgreich gelöscht.`,
  };
  public DELETE_USER_FAILED = {
    title: 'Fehler beim Löschen des Nutzers!',
    body: `Beim Löschen des Nutzers ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_USER_SUCCESS = {
    title: 'Nutzer erfolgreich angelegt!',
    body: `Der Nutzer wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_USER_FAILED = {
    title: 'Fehler beim Anlegen des Nutzers!',
    body: `Beim Anlegen des Nutzers ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_USER_SUCCESS = {
    title: 'Nutzer erfolgreich geändert!',
    body: `Der Nutzer wurde erfolgreich geändert.`,
  };
  public UPDATE_USER_FAILED = {
    title: 'Fehler beim Ändern des Nutzers!',
    body: `Beim Ändern des Nutzers ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_DAYCARE_SUCCESS = {
    title: 'Kita erfolgreich gelöscht!',
    body: `Die Kita wurde erfolgreich gelöscht.`,
  };
  public DELETE_DAYCARE_FAILED = {
    title: 'Fehler beim Löschen der Kita!',
    body: `Beim Löschen der Kita ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_DAYCARE_SUCCESS = {
    title: 'Kita erfolgreich angelegt!',
    body: `Die Kita wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_DAYCARE_FAILED = {
    title: 'Fehler beim Anlegen der Kita!',
    body: `Beim Anlegen der Kita ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_DAYCARE_SUCCESS = {
    title: 'Kita erfolgreich geändert!',
    body: `Die Kita wurde erfolgreich geändert.`,
  };
  public UPDATE_DAYCARE_FAILED = {
    title: 'Fehler beim Ändern der Kita!',
    body: `Beim Ändern der Kita ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_INSTITUTION_SUCCESS = {
    title: 'Träger erfolgreich gelöscht!',
    body: `Der Träger wurde erfolgreich gelöscht.`,
  };
  public DELETE_INSTITUTION_FAILED = {
    title: 'Fehler beim Löschen des Trägers!',
    body: `Beim Löschen des Träger ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_INSTITUTION_SUCCESS = {
    title: 'Träger erfolgreich angelegt!',
    body: `Der Träger wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_INSTITUTION_FAILED = {
    title: 'Fehler beim Anlegen des Trägers!',
    body: `Beim Anlegen des Träger ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_INSTITUTION_SUCCESS = {
    title: 'Träger erfolgreich geändert!',
    body: `Der Träger wurde erfolgreich geändert.`,
  };
  public UPDATE_INSTITUTION_FAILED = {
    title: 'Fehler beim Ändern des Trägers!',
    body: `Beim Ändern des Träger ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_GROUP_SUCCESS = {
    title: 'Gruppe erfolgreich gelöscht!',
    body: `Die Gruppe wurde erfolgreich gelöscht.`,
  };
  public DELETE_GROUP_FAILED = {
    title: 'Fehler beim Löschen der Gruppe!',
    body: `Beim Löschen der Gruppe ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_GROUP_SUCCESS = {
    title: 'Gruppe erfolgreich angelegt!',
    body: `Die Gruppe wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_GROUP_FAILED = {
    title: 'Fehler beim Anlegen der Gruppe!',
    body: `Beim Anlegen der Gruppe ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_GROUP_SUCCESS = {
    title: 'Gruppe erfolgreich geändert!',
    body: `Die Gruppe wurde erfolgreich geändert.`,
  };
  public UPDATE_GROUP_FAILED = {
    title: 'Fehler beim Ändern der Gruppe!',
    body: `Beim Ändern der Kind ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_CHILD_SUCCESS = {
    title: 'Kind erfolgreich gelöscht!',
    body: `Das Kind wurde erfolgreich gelöscht.`,
  };
  public DELETE_CHILD_FAILED = {
    title: 'Fehler beim Löschen des Kindes!',
    body: `Beim Löschen des Kindes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public ACTIVATE_CHILD_FAILED = {
    title: 'Fehler beim Aktivieren des Kindes!',
    body: `Beim Aktivieren des Kindes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_CHILD_SUCCESS = {
    title: 'Kind erfolgreich angelegt!',
    body: `Das Kind wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_CHILD_FAILED = {
    title: 'Fehler beim Anlegen des Kindes!',
    body: `Beim Anlegen des Kindes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_CHILD_SUCCESS = {
    title: 'Kind erfolgreich geändert!',
    body: `Das Kind wurde erfolgreich geändert.`,
  };
  public UPDATE_CHILD_FAILED = {
    title: 'Fehler beim Ändern des Kindes!',
    body: `Beim Ändern des Kindes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_SETTING_SUCCESS = {
    title: 'Einstellung erfolgreich gelöscht!',
    body: `Die Einstellung wurde erfolgreich gelöscht.`,
  };
  public DELETE_SETTING_FAILED = {
    title: 'Fehler beim Löschen der Einstellung!',
    body: `Beim Löschen der Einstellung ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_SETTING_SUCCESS = {
    title: 'Einstellung erfolgreich angelegt!',
    body: `Die Einstellung wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_SETTING_FAILED = {
    title: 'Fehler beim Anlegen der Einstellung!',
    body: `Beim Anlegen der Einstellung ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_SETTING_SUCCESS = {
    title: 'Einstellung erfolgreich geändert!',
    body: `Die Einstellung wurde erfolgreich geändert.`,
  };
  public UPDATE_SETTING_FAILED = {
    title: 'Fehler beim Ändern der Einstellung!',
    body: `Beim Ändern der Einstellung ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_TEMPLATE_SUCCESS = {
    title: 'Template erfolgreich gelöscht!',
    body: `Das Template wurde erfolgreich gelöscht.`,
  };
  public DELETE_TEMPLATE_FAILED = {
    title: 'Fehler beim Löschen des Templates!',
    body: `Beim Löschen des Templates ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_TEMPLATE_SUCCESS = {
    title: 'Template erfolgreich angelegt!',
    body: `Das Template wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_TEMPLATE_FAILED = {
    title: 'Fehler beim Anlegen des Templates!',
    body: `Beim Anlegen des Templates ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_TEMPLATE_SUCCESS = {
    title: 'Template erfolgreich geändert!',
    body: `Das Template wurde erfolgreich geändert.`,
  };
  public UPDATE_TEMPLATE_FAILED = {
    title: 'Fehler beim Ändern des Templates!',
    body: `Beim Ändern des Templates ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_TEMPLATE_AREA_SUCCESS = {
    title: 'Templatearea erfolgreich gelöscht!',
    body: `Die Templatearea wurde erfolgreich gelöscht.`,
  };
  public DELETE_TEMPLATE_AREA_FAILED = {
    title: 'Fehler beim Löschen der Templatearea!',
    body: `Beim Löschen der Templatearea ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_TEMPLATE_AREA_SUCCESS = {
    title: 'Templatearea erfolgreich angelegt!',
    body: `Die Templatearea wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_TEMPLATE_AREA_FAILED = {
    title: 'Fehler beim Anlegen der Templatearea!',
    body: `Beim Anlegen der Templatearea ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_TEMPLATE_AREA_SUCCESS = {
    title: 'Templatearea erfolgreich geändert!',
    body: `Die Templatearea wurde erfolgreich geändert.`,
  };
  public UPDATE_TEMPLATE_AREA_FAILED = {
    title: 'Fehler beim Ändern der Templatearea!',
    body: `Beim Ändern der Templatearea ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_TEMPLATE_COLOR_SUCCESS = {
    title: 'Templatefarbe erfolgreich gelöscht!',
    body: `Die Templatefarbe wurde erfolgreich gelöscht.`,
  };
  public DELETE_TEMPLATE_COLOR_FAILED = {
    title: 'Fehler beim Löschen der Templatefarbe!',
    body: `Beim Löschen der Templatefarbe ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_TEMPLATE_COLOR_SUCCESS = {
    title: 'Templatefarbe erfolgreich angelegt!',
    body: `Die Templatefarbe wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_TEMPLATE_COLOR_FAILED = {
    title: 'Fehler beim Anlegen der Templatefarbe!',
    body: `Beim Anlegen der Templatefarbe ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_TEMPLATE_COLOR_SUCCESS = {
    title: 'Templatefarbe erfolgreich geändert!',
    body: `Die Templatefarbe wurde erfolgreich geändert.`,
  };
  public UPDATE_TEMPLATE_COLOR_FAILED = {
    title: 'Fehler beim Ändern der Templatefarbe!',
    body: `Beim Ändern der Templatefarbe ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_PROJECT_SUCCESS = {
    title: 'Projekt erfolgreich gelöscht!',
    body: `Der Projekt wurde erfolgreich gelöscht.`,
  };
  public DELETE_PROJECT_FAILED = {
    title: 'Fehler beim Löschen des Projekts!',
    body: `Beim Löschen des Projekts ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_PRODUCT_SUCCESS = {
    title: 'Produkt erfolgreich gelöscht!',
    body: `Das Produkt wurde erfolgreich gelöscht.`,
  };
  public DELETE_PRODUCT_FAILED = {
    title: 'Fehler beim Löschen des Produktes!',
    body: `Beim Löschen des Produktes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_PRODUCT_SUCCESS = {
    title: 'Produkt erfolgreich angelegt!',
    body: `Das Produkt wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_PRODUCT_FAILED = {
    title: 'Fehler beim Anlegen des Produktes!',
    body: `Beim Anlegen des Produktes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_PRODUCT_SUCCESS = {
    title: 'Produkt erfolgreich geändert!',
    body: `Das Produkt wurde erfolgreich geändert.`,
  };
  public UPDATE_PRODUCT_FAILED = {
    title: 'Fehler beim Ändern des Produktes!',
    body: `Beim Ändern des Produktes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_PURCHASE_SUCCESS = {
    title: 'Kauf erfolgreich gelöscht!',
    body: `Der Kauf wurde erfolgreich gelöscht.`,
  };
  public DELETE_PURCHASE_FAILED = {
    title: 'Fehler beim Löschen des Kaufes!',
    body: `Beim Löschen des Kaufes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_PURCHASE_SUCCESS = {
    title: 'Kauf erfolgreich angelegt!',
    body: `Der Kauf wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_PURCHASE_FAILED = {
    title: 'Fehler beim Anlegen des Kaufes!',
    body: `Beim Anlegen des Kaufes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_PURCHASE_SUCCESS = {
    title: 'Kauf erfolgreich geändert!',
    body: `Der Kauf wurde erfolgreich geändert.`,
  };
  public UPDATE_PURCHASE_FAILED = {
    title: 'Fehler beim Ändern des Kaufes!',
    body: `Beim Ändern des Kaufes ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CANNOT_DELETE_SELF = {
    title: 'Fehler beim Löschen des Nutzers!',
    body: `Sie dürfen den Nutzer löschen mit dem Sie angemeldet sind.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public FILE_UPLOAD_FAILED = {
    title: 'Fehler beim hochladen der Datei!',
    body: `Beim hochladen der Datei ist ein Fehler aufgetreten!\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_CLIP_ART_SUCCESS = {
    title: 'Clip-Art erfolgreich gelöscht!',
    body: `Das Clip-Art wurde erfolgreich gelöscht.`,
  };
  public DELETE_CLIP_ART_FAILED = {
    title: 'Fehler beim Löschen des Clip-Art!',
    body: `Beim Löschen des Clip-Art ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_CLIP_ART_SUCCESS = {
    title: 'Clip-Art erfolgreich angelegt!',
    body: `Das Clip-Art wurde erfolgreich angelegt. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public CREATE_CLIP_ART_FAILED = {
    title: 'Fehler beim Anlegen des Clip-Art!',
    body: `Beim Anlegen des Clip-Art ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public UPDATE_CLIP_ART_SUCCESS = {
    title: 'Clip-Art erfolgreich geändert!',
    body: `Das Clip-Art wurde erfolgreich geändert.`,
  };
  public UPDATE_CLIP_ART_FAILED = {
    title: 'Fehler beim Ändern des Clip-Art!',
    body: `Beim Ändern des Clip-Art ist ein Fehler aufgetreten.\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public INVALID_DATE = {
    title: 'Es wurde ein ungültiges Datum ausgewählt!',
    body: `Bitte überprüfen sie Ihre Eingabe!\n ${this.MESSAGE_PLACEHOLDER}`,
  };
  public LOAD_ITEMS_FAILED = {
    title: 'Laden der Projekt-Elemente fehlgeschlagen.',
    body: `Die Elemente des Projektes '${this.MESSAGE_PLACEHOLDER}' konnten nicht geladen werden.`,
  };
  public DELETE_MULTIPLE_PROJECT_FAILED = {
    title: 'Beim Löschen der Projekte gab es einen Fehler.',
    body: `Es gab einen Fehler beim löschen der ausgewählten Projekte. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DELETE_MULTIPLE_PROJECT_SUCCESS = {
    title: 'Projekte erfolgreich gelöscht!.',
    body: `Die ausgewählten Projekte wurden erfolgreich gelöscht. ${this.MESSAGE_PLACEHOLDER}`,
  };
  public DOWNLOAD_COMPLETE = {
    title: 'Download abgeschlossen',
    body: ``,
  };

  public UPDATE_PROJECT_FAILED = {
    title: 'Fehler beim aktualisieren des Projektes',
    body: '',
  };

  public TOAST_STATUS = {
    primary: 0,
    success: 1,
    info: 2,
    warning: 3,
    danger: 4,
  };
  private types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];
  private defaultSuccessDuration = 5000;
  private hasIcon = true;
  private position = NbGlobalPhysicalPosition.TOP_RIGHT;
  private preventDuplicates = false;
  private logErrors = true;

  constructor(
    private toastrService: NbToastrService
  ) { }

  public showToast(type: number, message: any, durationInMs: number = -1, additionalInfo: any = '') {
    if (durationInMs === -1) {
      if (type === 1){
        durationInMs = this.defaultSuccessDuration;
      } else {
        durationInMs = 0;
      }
    }
    const config = {
      status: this.types[type],
      destroyByClick: true,
      duration: durationInMs,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };

    if(additionalInfo instanceof HttpErrorResponse){
      if (additionalInfo.error != undefined && additionalInfo.error.errors != undefined && additionalInfo.error.errors[0]) {
        additionalInfo = additionalInfo.error.errors[0];
      } else {
        additionalInfo = '';
      }
    }

    const titleContent = message.title ? `${message.title}` : '';
    const bodyContent = message.body ? `${message.body.replace(this.MESSAGE_PLACEHOLDER,additionalInfo)}` : '';

    this.toastrService.show(
      bodyContent,
      titleContent,
      config
    );
  }

  public downLoadFile(data: any, name: string, type: string = 'application/octet-stream'): void{
    let blob = new Blob([data], { type});
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.setAttribute('type','hidden');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public downloadBase64File(data: string, name: string): void{
    let link = document.createElement('a');
    link.href = data;
    link.download = name;
    link.click();
    link.remove();
  }

  public async logError(...params: any[]): Promise<void> {
    if (this.logErrors) {
      for (const x of params) {
        // tslint:disable-next-line:no-console
        console.log(x);
        if (x.error != undefined && x.error instanceof Blob) {
          await x.error.text().then(z => {
            if(z != undefined && z.length > 0){
              try{
                // tslint:disable-next-line:no-console
                console.log('parsed error blob:',JSON.parse(z));
              }catch (e){
                // tslint:disable-next-line:no-console
                console.log('blob error parse failed');
              }
            }
          });
        }
      }
    }
  }

  public formatDate(timestamp: number): string{
    let date = new Date(timestamp);
    if(date == undefined) return 'N/A';
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
  }

}
